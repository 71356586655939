<template>
  <a-layout id="app-layout-sider">
    <a-layout-sider v-model="collapsed" theme="light" class="layout-sider">
      <div class="logo">
        <img class="pic-logo" src="~@/assets/logo.png" />
      </div>
      <a-menu class="menu-item" theme="light" mode="inline" :default-selected-keys="[default_key]" @click="menuHandle">
        <a-menu-item v-for="(menuInfo, index) in menu" :key="index">
          <a-icon :type="menuInfo.icon" />
          {{ menuInfo.title }}
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-content class="layout-content">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
export default {
  name: "AppSider",
  data() {
    return {
      collapsed: true,
      default_key: "menu_1",
      current: "",
      menu: {
        menu_1: {
          icon: "play-circle",
          title: "视频",
          pageName: "video",
          params: {},
        },
        menu_2: {
          icon: "video-camera",
          title: "直播",
          pageName: "live",
          params: {},
        },
        menu_3: {
          icon: "read",
          title: "文案",
          pageName: "text",
          params: {},
        },
        menu_4: {
          icon: "user",
          title: "我的",
          pageName: "my",
          params: {},
        },
        menu_5: {
          icon: "setting",
          title: "设置",
          pageName: "setting",
          params: {},
        },
      },
    };
  },
  created() {},
  mounted() {
    this.menuHandle();
    
  },
  methods: {
    menuHandle(e) {
      this.current = e ? e.key : this.default_key;
      const linkInfo = this.menu[this.current];
      console.log("[home] load page:", linkInfo.pageName);
      this.$router.push({ name: linkInfo.pageName, params: linkInfo.params });
    },
  },
};
</script>
<style lang="less" scoped>
// 嵌套
#app-layout-sider {
  height: 100%;
  .logo {
    border-bottom: 1px solid #e8e8e8;
  }
  .pic-logo {
    height: 32px;
    //background: rgba(139, 137, 137, 0.2);
    margin: 10px;
  }
  .layout-sider {
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
  }
  .menu-item {
    .ant-menu-item {
      background-color: #fff;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0 0px !important;
    }
  }
  .layout-content {
    background-color: #fff;
  }
}
::v-deep{
  .ant-menu-item-selected,
  .ant-menu-item:hover{
    color: #409EFF;
  }
}
</style>
