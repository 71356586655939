/**
 * 基础路由
 * @type { *[] }
 */
import { AppSider, Menu } from '@/layouts'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const constantRouterMap = [
  {
    path: "/login",
    name: "login",
    component: () => import('@/views/login/index')
  },
  {
    path: '/',
    component: AppSider,
    children: [
      {
        path: '/client',
        name: 'client',
        component: RouteView,
        redirect: { name: 'video' },
        children: [
          {
            path: '/client/video/index',
            name: 'video',
            meta: { isLogin: true },
            component: () => import('@/views/video/index')
          },
          {
            path: '/client/my/index',
            name: 'my',
            meta: { isLogin: true },
            component: () => import('@/views/my/index')
          },
          {
            path: '/client/setting/index',
            name: 'setting',
            meta: { isLogin: true },
            component: () => import('@/views/setting/index')
          },
          {
            path: '/client/live/index',
            name: 'live',
            meta: { isLogin: true },
            component: () => import('@/views/live/index')
          },
          {
            path: '/client/gpt/index',
            name: 'text',
            meta: { isLogin: true },
            component: () => import('@/views/gpt/index')
          }
        ]
      },
      // {
      //   path: '/example',
      //   name: 'Example',
      //   component: Menu,
      //   props: { id: 'example' },
      //   redirect: { name: 'ExampleHelloIndex' },
      //   children: [
      //     {
      //       path: '/example/hello/index',
      //       name: 'ExampleHelloIndex',
      //       component: () => import('@/views/example/hello/Index')
      //     },
      //   ]  
      // },
    ]
  },
  {
    path: '/special',
    component: RouteView,
    children: [
      {
        path: 'subwindow',
        name: 'SpecialSubwindowIpc',
        component: () => import('@/views/liveStreaming/index')
      }
    ]
  },
]
