
//方法一
/* 需传参，n为是否必填参数 */
export const isPhoneRule = (n) => {
  return [
    {
      required: n,
      message: "不能为空",
      trigger: "blur",
    },
    {
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: '请输入正确电话号码',
    },
  ];
}

//方法二
/* 不需要传参，直接使用即可 */
export const isPhone = (rule, value, callback) => {
  let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  if (value != "") {
    if (reg.test(value)) {
      callback();
    } else {
      callback('请输入正确联系方式')
    }
  } else {
    callback();
  }
}

/* 打乱数组顺序 */
export function shuffle(arr) {
  for (let i = 0; i < arr.length; i++) {
    const randomIndex = Math.round(Math.random() * (arr.length - 1 - i)) + i;
    [arr[i], arr[randomIndex]] = [arr[randomIndex], arr[i]]
  }
  return arr
}



/*  */
export function downloadVideo(url, name) {
  if (url) {
    fetch( url).then(res => res.blob()).then(blob => {
    // fetch('/download/' + url).then(res => res.blob()).then(blob => {
      console.log('download:'+name);
      const a = document.createElement('a');
      document.body.appendChild(a)
      a.style.display = 'none'
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = name + '.mp4'; //视频下载后的名称
      a.click();
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url);
      return true
    })
  }


}