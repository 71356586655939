import Vue from 'vue'
import Router from 'vue-router'
import { constantRouterMap } from '@/config/router.config'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
const router = new Router({
  mode: 'hash',
  routes: constantRouterMap
})
router.beforeEach((to, from, next) => {
  // next()

  if(to.meta.isLogin && !localStorage.getItem('token')){
    return next("/login");
  }else{
    next()
  }


  //检查用户是否登录
  // console.log(this.$ipc);
  // next()

})
export default router
