import Vue from "vue"
import Vuex from "vuex"
import { downloadVideo, sysDownload } from "@/utils/tools";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pathName: "",
    srcList: [],
    nowList: [],
    listLen: 0,
    nowLen: 0,
    SavePath: null,
    mInterval: null,
  },
  mutations: {
    saveSrcList(state, srcList) {
      state.listLen = srcList.length
      let arr = []
      srcList.map(i => {
        downloadVideo(i.url, i.title+'-'+i.id)
        arr.push(i)
      })
      state.srcList = [...state.srcList, ...arr]
    },

    saveListLen(state, listLen) {
      state.listLen = listLen
    },
    setSavePath(state, SavePath) {
      state.SavePath = SavePath
    },
    setnowList(state, nowList) {
      state.nowLen = 0
      state.nowList = []
      console.log('arrlist', nowList);
      state.nowLen = nowList.length
      nowList.map(i => {
        downloadVideo(i.url, i.saveName)
        i.isdownload = true
        state.nowList.push(i)
      })
    },
    setmInterval(state, mInterval) {
      state.mInterval = mInterval
    }
  }
})